<template>
  <div class="product-box">
    <img
      :src="product.thumbnail_image"
      alt="缩略图"
      class="thumbnail-img"
      @click="toProductDetail(product.product_id)"
    />
    <div class="margin-left-sm flex flex-direction justify-between flex-1 padding-right text-lg">
      <div>
        <div>
          {{
            product.product_title_cn
              ? product.product_title_cn
              : product.product_title_jp
          }}
        </div>
        <div class="margin-top-xs">
          {{ product.spec_title_cn ? product.spec_title_cn : product.spec_title_jp }}
        </div>
        <div class="margin-top-sm text-bold">
          {{ product.selling_price_text }}日元
          <span class="text-df">
            (约<span class="text-gold">{{ product.selling_price_rmb_text }}</span>人民币)
          </span>
        </div>
      </div>
    </div>
    <div class="text-gray">×{{ product.quantity }}</div>
  </div>
</template>

<script>
export default {
  name: "ProductIntroRow",
  props: {
    product: Object,
  },
  data() {
    return {};
  },
  methods: {
    toProductDetail(product_id) {
      this.$router.push({
        path: "/product_detail",
        query: { product_id },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.product-box {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
}

.thumbnail-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
</style>
