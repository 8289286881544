<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar :title="category_mst.title_cn" />

    <!-- 内容 -->
    <div class="padding-cu">
      <div v-if="!!category_mst.description " class="margin-top-xs text-pre-wrap paragraph">{{category_mst.description }}</div>
      <!-- 分类tab -->
      <van-tabs
        v-model="active_tab"
        background="#484f55"
        color="#B9AC72"
        title-inactive-color="#fff"
        title-active-color="#B9AC72"
        @click="tabSelect"
      >
        <van-tab
          v-for="(tab, index) in tab_list"
          :title="tab.title_cn"
          :name="tab.id"
          :key="index"
        ></van-tab>
      </van-tabs>
      <!-- product -->
      <div
        v-if="product_list.length > 0"
        class="flex flex-wrap product-wrap margin-top-xs"
      >
        <div v-for="(product, index) in product_list" :key="index">
          <ProductIntroColumn :product="product" />
        </div>
      </div>
      <van-empty v-else :image="require('@/assets/images/logo.png')" />
    </div>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import ProductIntroColumn from "../../components/ProductIntroColumn.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { getCategoryList, getProductListByCategory } from "../../https/http";

export default {
  name: "ProductListByCategory",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
    ProductIntroColumn,
  },
  data() {
    return {
      category_mst: {}, // 分类信息
      product_list: [], // product列表
      tab_list: [],
      active_tab: null,
    };
  },
  created() {
    let category_id = this.$route.query.category_id;
    this.active_tab = category_id;
    getCategoryList(category_id).then((res) => {
      this.tab_list = res.data;
      this.tab_list.unshift({
        id: category_id,
        title_cn: "全部",
      });
    });
    getProductListByCategory(category_id).then((res) => {
      this.category_mst = res.data.category_mst;
      this.product_list = res.data.product_list;
    });
  },
  mounted() {},
  methods: {
    tabSelect(e) {
      let category_id = e;
      getProductListByCategory(category_id).then((res) => {
        this.category_mst = res.data.category_mst;
        this.product_list = res.data.product_list;
      });
    },
  },
};
</script>

<style scoped>
.product-wrap {
  gap: 0.53rem;
}

/deep/ .van-empty__image {
  width: 133px;
  height: 80px;
}

.paragraph {
  font-size: large;
  line-height: 1.5em;
}
</style>
