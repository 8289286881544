<template>
  <div
    class="footer-space"
    @touchstart="touchstart"
    @touchmove="touchmove"
    @touchend="touchend"
  >
    <!-- 头部导航 -->
    <NavigationBar :key="refresh_navigationbar_key" />
    <!-- 标题栏 -->
    <TitleBar title="商品详情" />

    <!-- 内容 -->
    <!-- 轮播图或封面图 -->
    <van-swipe :autoplay="6000" indicator-color="#b9ac72">
      <van-swipe-item v-for="(image, index) in product.images" :key="index">
        <img :src="image" alt="kv" class="kv-img" />
      </van-swipe-item>
    </van-swipe>

    <div class="padding-cu">
      <!-- 产品标题 -->
      <div class="margin-top-xl text-xl text-bold">
        {{ product.title_cn ? product.title_cn : product.title_jp }}
      </div>
      <!-- 产品价格 -->
      <div class="margin-top-sm text-gray">
        <span
          v-if="product.selling_price !== product.list_price"
          class="text-del margin-right-xs"
          >{{ product.list_price }}日元</span
        >
        <span class="text-lg">{{ product.selling_price }}</span
        >日元
      </div>
      <div class="margin-top-xs text-bold">
        <span
          v-if="product.selling_price !== product.list_price"
          class="text-del margin-right-xs text-gray"
        >
          约{{ product.list_price_rmb }}人民币
        </span>
        约<span class="text-lg text-gold">{{ product.selling_price_rmb }}</span
        >人民币
      </div>
      <!-- 分类 -->
      <div class="margin-top text-gray">
        <div
          class="margin-top-xs"
          v-for="(category, index) in product.category_list"
          :key="index"
        >
          <template v-for="(item, key) in category">
            <span @click="toProductListByCategory(item.id)">
              {{ item.title_cn }}
            </span>
            <van-icon
              v-if="key !== category.length - 1"
              name="arrow"
              custom-style="top:2px;"
              class="margin-lr-xxs"
            />
          </template>
        </div>
      </div>
      <van-tabs
        v-model="active_tab"
        background="#4B4E52"
        color="#e3d17d"
        title-active-color="#e3d17d"
        title-inactive-color="#ccc"
        class="margin-top"
      >
        <van-tab title="商品详情" name="product_detail">
          <!-- 图片和图片描述 -->
          <div>
            <div
              v-for="(item, index) in product.descriptions"
              :key="index"
              class="margin-top-sm"
            >
              <img
                :src="item.description_image"
                alt="description image"
                class="description-img"
              />
              <div
                v-if="!!item.description_text"
                class="margin-top-xxs text-pre-wrap"
              >
                {{ item.description_text }}
              </div>
            </div>
          </div>
          <!-- 商品描述 -->
          <div class="margin-top text-pre-wrap">
            {{ product.description_cn }}
          </div>

          <template v-if="!!product.skus && product.skus.length > 0">
            <!-- sku选择 -->
            <div class="margin-top flex align-center">
              <span>选择</span>
              <div class="margin-left flex flex-wrap align-center">
                <img
                  v-for="(item, index) in product.skus"
                  :key="index"
                  :src="item.thumbnail_image"
                  :class="{
                    'sku-thumbnail margin-top-xs margin-right-sm': true,
                    'sku-thumbnail-selected': index == sku_index,
                  }"
                  @click="selectSku(index)"
                />
              </div>
            </div>
            <!-- 数量选择 -->
            <div class="margin-top-sm flex align-center">
              <span>数量</span>
              <van-stepper
                class="margin-left"
                v-model="sku_quantity"
                min="1"
                :max="product.skus[sku_index].stock"
                @change="selectQuantity"
              />
            </div>

            <div class="margin-top-sm text-bold">
              <div>
                {{
                  product.skus[sku_index].spec_title_cn
                    ? product.skus[sku_index].spec_title_cn
                    : product.skus[sku_index].spec_title_jp
                }}
              </div>
              <div class="margin-top-xs">
                <span
                  >约<span class="text-gold text-lg">{{
                    product.skus[sku_index].selling_price_rmb
                  }}</span
                  >人民币<span class="margin-left-xm text-xxs"
                    >（{{
                      product.skus[sku_index].selling_price_text
                    }}日元）</span
                  ></span
                >
              </div>
            </div>
          </template>
          <!-- 购买按钮 -->
          <div class="margin-top">
            <!-- 入力フォーム -->
            <form>
              <input
                v-if="
                  !!product.skus &&
                  (product.skus.length < 1 || product.skus[sku_index].stock < 1)
                "
                type="button"
                value="售罄"
                class="input-button"
              />
              <input
                v-else
                type="button"
                value="加入购买车"
                class="input-button submit-after"
                @click="addCart"
              />
              <input
                v-if="this.$globalData.login_status !== 'MEMBER'"
                type="button"
                value="收藏"
                class="input-button"
                @click="toLogin"
              />
              <input
                v-else
                type="button"
                value="收藏"
                class="input-button submit-after"
                @click="addFavorite"
              />
            </form>
          </div>
        </van-tab>
        <van-tab title="评论" name="product_review">
         
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="loadUserReview"
            >
              <div
                class="margin-top-sm text-sm review-wrap"
                v-for="item in user_review_list"
              >
                <div class="flex justify-between align-item text-gray">
                  <span>{{ item.full_name }}</span>
                  <span class="margin-left-xs">{{ item.update_date }}</span>
                </div>
                <van-rate
                  :value="Number(item.rating)"
                  :size="15"
                  color="#E3D17D"
                  void-icon="star"
                  void-color="#eee"
                  readonly
                  class="margin-left-sm margin-top-xs"
                />
                <div class="margin-left-sm margin-top-xs text-pre-wrap">{{ item.comment }}</div>
              </div>
            </van-list>
        </van-tab>
      </van-tabs>
    </div>

    <div
      class="service_box"
      :class="move_flg ? 'service-box-invalid' : ''"
      @click="toCart"
    >
      <div class="cart-badge">{{ cart_num }}</div>
    </div>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import {
  addCartProduct,
  addFavoriteProduct,
  getProductDetail,
  getProductReview,
} from "../../https/http";

// vant弹窗提示
import { Toast } from "vant";

export default {
  name: "ProductDetail",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      product: [], // product详情信息
      sku_index: 0, // 被选中的sku序号
      sku_quantity: 1, // 被选中的sku的数量
      stock: 0, //库存量
      refresh_navigationbar_key: 0, // 初始 key 值
      user_review_list: [], // product评论列表
      active_tab: "product_detail",
      user_review_offset: 0,
      user_review_limit: 20,
      // 购物车图标用
      start_x: 0,
      start_y: 0,
      move_x: 0,
      move_y: 0,
      move_flg: false,

      // 评论下拉刷新
      loading: false,
      finished: false,
    };
  },
  computed: {
    cart_num() {
      return this.$globalData.cart_num;
    },
  },
  created() {
    let product_id = this.$route.query.product_id;
    getProductDetail(product_id).then((res) => {
      this.product = res.data;
      this.stock = this.product.skus[this.sku_index].stock;
    });
  },
  mounted() {},
  methods: {
    toProductListByCategory(category_id) {
      this.$router.push({
        path: "/product_list_by_category",
        query: { category_id },
      });
    },

    /**
     * 选择sku数量
     */
    selectQuantity() {},

    /**
     * 选择sku型号
     */ selectSku(index) {
      this.sku_index = index;
      this.sku_quantity = 1;
    },

    /**
     * 加入购物车
     */
    addCart() {
      let product_sku_id = this.product.skus[this.sku_index].id;
      let quantity = this.sku_quantity;
      // 本地存储
      this.saveLocalCart(product_sku_id, quantity);
      if (!!this.$globalData.login_status) {
        //登录状态 数据库存储  TODO
        this.saveRemoteCart(product_sku_id, quantity);
      } else {
        Toast.success("加入购物车成功");
      }
    },
    /**
     * 商品加入本地localStorage购物车
     */
    saveLocalCart(product_sku_id, quantity) {
      let cart = JSON.parse(localStorage.getItem("cart"));
      if (!cart) {
        cart = [];
      }
      cart.push({ product_sku_id, quantity });

      // 使用 reduce 来合并相同 product_sku_id 的对象
      let result = cart.reduce((acc, current) => {
        // 查找是否已经存在相同的 product_sku_id
        let existing = acc.find(
          (item) => item.product_sku_id === current.product_sku_id
        );
        if (existing) {
          // 如果存在，累加 quantity
          existing.quantity += current.quantity;
        } else {
          // 如果不存在，直接添加到结果数组
          acc.push(current);
        }
        return acc;
      }, []);
      localStorage.setItem("cart", JSON.stringify(result));
      if (!this.$globalData.login_status) {
        this.$globalData.cart_num = result.length; // 修改全局变量
        this.refresh_navigationbar_key += 1; // 更新 key，触发重新渲染
      }
    },
    /**
     * 商品加入服务器数据库购物车
     */
    saveRemoteCart(product_sku_id, quantity) {
      addCartProduct({ product_sku_id, quantity }).then((res) => {
        this.$globalData.cart_num = res.data.cart_num; // 修改全局变量
        this.refresh_navigationbar_key += 1; // 更新 key，触发重新渲染
        if (res.data.status === "OK") {
          Toast.success("加入购物车成功");
        } else {
          Toast.success("加入购物车失败");
        }
      });
    },

    /**
     * 跳转登录页面
     */
    toLogin() {
      this.$globalData.after_login_path =
        "/product_detail?product_id=" + this.product.product_id;
      this.$router.push({ path: "/login" });
    },
    /**
     * 加入收藏
     */
    addFavorite() {
      let product_id = this.product.product_id;
      addFavoriteProduct({ product_id }).then((res) => {
        if (res.data == "OK") {
          Toast.success("加入收藏成功");
        } else {
          Toast.fail("加入收藏失败");
        }
      });
    },

    toCart() {
      this.$router.push({ path: "/cart" });
    },

    // 为购物车悬浮按钮服务
    touchstart(e) {
      this.start_x = e.touches[0].clientX;
      this.start_y = e.touches[0].clientY;
    },
    touchmove(e) {
      this.move_x = e.touches[0].clientX;
      this.move_y = e.touches[0].clientY;
      if (Math.abs(this.start_y - this.move_y) > 5) {
        this.move_flg = true;
      }
    },
    touchend(e) {
      this.move_flg = false;
    },

    loadUserReview() {
      let product_id = this.$route.query.product_id;
      let data = {
        product_id: product_id,
        user_review_offset: this.user_review_offset,
        user_review_limit: this.user_review_limit,
      };
      getProductReview(data).then((res) => {
        this.user_review_list = [...this.user_review_list, ...res.data.user_review_list]; // 合并新数据
        this.user_review_offset += this.user_review_limit
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if(res.data.user_review_list.length < this.user_review_limit) {
          this.finished = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.kv-img {
  width: 100%;
  height: 420px;
  object-fit: cover;
}

.description-img {
  width: 100%;
}

.sku-thumbnail {
  width: 160px;
  height: 160px;
}

.sku-thumbnail-selected {
  border: 4px solid #b9ac72;
}

.service_box {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid var(--main-gray-color);
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
  background-color: black;
  background-image: url("../../assets/images/icon/icon-cart.svg");
  background-position: center center;
  /* 水平垂直居中 */
  background-repeat: no-repeat;
  /* 不平铺 */
  background-size: 60%;
  /* 背景图片覆盖整个容器 */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 100px;
  right: 20px;

  transition: 1s;

  z-index: 999;
}

.service-box-invalid {
  right: -40px;
  opacity: 0;
}

.cart-badge {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ff4a49;
  border: 2px solid white;
  position: absolute;
  top: -5px;
  right: -10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-wrap {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(100, 100, 100, 0.5);
}
</style>
