// 导入axios
import axios from 'axios'
// 导入config
import config from '../config'
// 导入vant
import {
  Toast,
  Dialog
} from 'vant'
// 使用自定义配置新建一个axios 实例，对axios 做一些基础配置
const instance = axios.create({
  baseURL: config.api_host,
  timeout: 5000,
  headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 显示 loading
  Toast.loading({
    duration: 0, // 持续显示直到手动关闭
  });
  //请求之前执行该函数， 一般在该处设置token 
  let user_session_key = sessionStorage.getItem("user_session_key") ?? localStorage.getItem("user_session_key")
  if (!!user_session_key) {
    config.headers["X-USER-SESSION-KEY"] = user_session_key
  }
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

//响应拦截器
instance.interceptors.response.use(response => {
  // 请求成功，关闭 loading
  Toast.clear();
  //1.非200响应
  if (response.data.error_code !== 200) {
    Dialog.alert({
      title: '错误提示',
      message: response.data.error_message
    })
    return Promise.reject(response.data.error_message);
  }
  //2.token过期
  //3.异地登陆
  //4.非对象加密的解密

  return response.data
})
export default instance