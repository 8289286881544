<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="订单列表" />

    <!-- 内容 -->
    <div class="padding-cu">
      <!-- 订单信息 -->
      <div
        v-for="(order, index) in order_list"
        :key="index"
        class="margin-top padding-bottom border-bottom-gray order-box"
      >
        <div class="text-sm">{{ order.order_date }}</div>
        <div class="text-sm margin-top-xs">订单号: {{ order.order_no }}</div>
        <!-- 商品信息 -->
        <ProductIntroRow
          v-for="(product, index) in order.order_product_list"
          :key="index"
          :product="product"
        />
        <div class="info-item total-amount">
          <span>合计</span>
          <span
            >{{ order.total_text }}日元 (约
            <span class="price-highlight">{{ order.total_rmb_text }}</span
            >人民币)</span
          >
        </div>
        <form class="flex align-center justify-end">
          <input
            v-if="order.status == 'PAID' || order.status == 'SHIPPED'"
            type="button"
            value="确认收货"
            class="input-button submit-after"
            @click="confirmReceived(order.order_id)"
          />
          <template
            v-if="
              order.status == 'PAID' ||
              order.status == 'SHIPPED' ||
              order.status == 'RECEIVED'
            "
          >
          <input
              v-if="order.is_reviewed"
              type="button"
              value="已评论"
              class="input-button submit-before margin-left-sm"
            />
            <input
              v-else
              type="button"
              value="评论"
              class="input-button submit-after margin-left-sm"
              @click="reviewtOrder(order.order_id)"
            />
          </template>

          <input
            type="button"
            value="订单详情"
            class="input-button submit-after margin-left-sm"
            @click="toOrderDetail(order.order_id)"
          />
        </form>

        <div
          v-if="order.status == 'NEW' || order.status == 'IN_PAYMENT'"
          class="status-tag"
        >
          待支付
        </div>
        <div
          v-else-if="order.status == 'PAID' || order.status == 'SHIPPED'"
          class="status-tag"
        >
          待收货
        </div>
        <div v-else-if="order.status == 'RECEIVED'" class="status-tag">
          已收货
        </div>
        <div v-else-if="order.status == 'REFUND'" class="status-tag">
          已退款
        </div>
        <div v-else-if="order.status == 'CANCELED'" class="status-tag">
          已取消
        </div>
      </div>
    </div>

    <!-- 评论商品选择弹窗 -->
    <van-dialog v-model="show_review_product" :showConfirmButton="false">
      <div class="padding flex flex-direction align-center">
        <ProductReview
          v-for="(product, index) in review_product_list"
          :key="index"
          :product="product"
        />
        <form>
          <input
            type="button"
            value="返回"
            class="input-button submit-after margin-top-xxl"
            @click="closeReviewProduct"
          />
        </form>
      </div>
    </van-dialog>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import ProductIntroRow from "../../components/ProductIntroRow.vue";
import TitleBar from "../../components/TitleBar.vue";
import ProductReview from "../../components/ProductReview.vue";

// 接口
import { getOrderList, updateOrderStatus } from "../../https/http";

// vant弹窗提示
import { Dialog, Toast } from "vant";
import util from "../../utils/util";

export default {
  name: "OrderHistory",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
    ProductIntroRow,
    ProductReview,
  },
  data() {
    return {
      order_list: [], //订单的列表
      show_review_product: false, // 评论商品弹窗的显示
      review_product_list: [], // 评论商品列表
    };
  },
  created() {
    getOrderList().then((res) => {
      this.order_list = res.data;
    });
  },
  mounted() {},
  methods: {
    confirmReceived(order_id) {
      updateOrderStatus({ order_id, status: "RECEIVED" }).then((res) => {
        this.order_list = res.data;
      });
    },

    toOrderDetail(order_id) {
      this.$router.push({
        path: "/order_detail",
        query: { order_id },
      });
    },
    /**
     * 订单评论
     */
    reviewtOrder(order_id) {
      let order = this.order_list.find((item) => item.order_id == order_id);
      // 如果该订单有多个商品
      if (util.isEmpty(order.order_product_list)) {
        return;
      }
      if (order.order_product_list.length > 1) {
        this.show_review_product = true;
        this.review_product_list = order.order_product_list;
      } else {
        this.toReviewInput(order.order_product_list[0].order_detail_id);
      }
    },
    /**
     * 订单评论
     */
    toReviewInput(order_detail_id) {
      this.$router.push({
        path: "/review_input",
        query: { order_detail_id },
      });
    },
    /**
     * 关闭评论商品选择弹窗
     */
    closeReviewProduct() {
      this.show_review_product = false;
    },
  },
};
</script>

<style scoped>
.total-amount {
  font-size: 0.35rem;
}

form .input-button {
  width: 7em;
  padding: 0.5em;
}

.order-box {
  position: relative;
}

.status-tag {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 36px;
  border-radius: 40px;
  border: 1px solid #B9AC72;
  font-size: 11PX;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
