<template>
  <div class="footer">
    <img src="../assets/images/logo.png" class="logo" @click="scrollToTop"/>
    <div class="text-sm margin-top">&copy;2024 IREY PHARMACY</div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  data() {
    return {};
  },
  methods: {
    scrollToTop() {
      // 如果需要平滑滚动，可以使用以下代码：
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // 平滑滚动
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  position: absolute;
  bottom: 0;
  width: 100%;
}

.logo {
  height: 96px;
}
</style>
