<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="我的" />

    <!-- 内容 -->
    <div class="padding-cu margin-top-xl">
      <div class="page-user">
        <div class="page-user-icon">
          <img
            src="../../assets/images/icon/icon-user-2.svg"
            alt="Category 1"
          />
        </div>
        <p>{{ full_name }}</p>
      </div>

      <!-- 入力フォーム -->
      <form class="margin-top-sm">
        <!--ボタン-->
        <input type="button" value="订单" class="input-button" @click="toOrderHistory"/>
        <input type="button" value="收藏" class="input-button"  @click="toFavorite"/>
        <input type="button" value="管理收货地址" class="input-button" @click="toShippingAddressEdit"/>
        <input type="button" value="利用指南" class="input-button" @click="toUsageGuide"/>
      </form>
    </div>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { getUserInfo } from "../../https/http";

// vant弹窗提示

export default {
  name: "Mypage",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      full_name: "",
    };
  },
  created() {
    getUserInfo().then((res) => {
      this.full_name = res.data.full_name;
    });
  },
  mounted() {},
  methods: {
    toOrderHistory() {
      this.$router.push({ path: "/order_history" });
    },
    toShippingAddressEdit() {
      this.$router.push({ path: "/shipping_address_edit" });
    },
    toFavorite() {
      this.$router.push({ path: "/favorite" });
    },
    // 利用指南按钮
    toUsageGuide() {
      this.$router.push({ path: "/usage_guide" });
    }
  },
};
</script>

<style scoped>
.page-user {
  margin: 0 auto 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-user .page-user-icon {
  background-color: #fff;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-user .page-user-icon img {
  width: 100px;
  height: 100px;
}

.page-user p {
  font-weight: bold;
}
</style>
