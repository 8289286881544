<template>
  <div class="flex flex-direction align-center">
    <img
      src="../assets/images/navigation_browser_1.png"
      class="img right-item"
    />
    <div>
      <div class="margin-top-xxxl">
        <van-badge
          :content="1"
          color="#E3D17D"
          class="margin-right-xxs"
        ></van-badge
        >点击右上角的按钮
      </div>
      <div class="margin-top">
        <van-badge
          :content="2"
          color="#E3D17D"
          class="margin-right-xxs"
        ></van-badge
        >选择在浏览器中打开
      </div>
    </div>
    <img
      src="../assets/images/navigation_browser_2.png"
      class="img margin-top-xxxl"
    />
    <img
      src="../assets/images/navigation_browser_3.png"
      class="img margin-top-xxl"
    />
  </div>
</template>

<script>
export default {
  name: "NavigationBrowser",
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.img {
  width: 150px;
}
.right-item {
  align-self: flex-end; /* 单独设置靠右 */
  margin-right: 40px;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #e3d17d;
}
</style>
