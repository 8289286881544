<template>
  <div class="product-intro-column" @click="toProductDetail(product.id)">
    <div class="thumbnail-img-box">
      <img :src="product.thumbnail_image" alt="" class="thumbnail-img" />
    </div>
    <div class="text-sm margin-top-xs">{{ product.brand_title }}</div>
    <div class="brand-title text-lg margin-top-xxs">{{ product.title_cn }}</div>
    <div class="text-sm margin-top-xs">
      约<span class="text-gold">{{ product.selling_price_rmb }}</span
      >元
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductIntroColumn",
  props: {
    product: Object,
  },
  data() {
    return {};
  },
  methods: {
    toProductDetail(product_id) {
      this.$router.push({
        path: "/product_detail",
        query: { product_id },
      });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.product-intro-column {
  width: 320px;
  overflow: hidden;
  text-align: left;
}

.thumbnail-img-box {
  width: 100%;
  height: 320px;
  background: white;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.brand-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
