// src/router/index.js

import Vue from 'vue';
import VueRouter from 'vue-router';

// 导入组件
import Cart from '@/pages/cart/Cart.vue';
import Home from '@/pages/index/Home.vue';
import OrderConfirm from '@/pages/order/OrderConfirm.vue';
import OrderDetail from '@/pages/order/OrderDetail.vue';
import OrderFinish from '@/pages/order/OrderFinish.vue';
import OrderHistory from '@/pages/order/OrderHistory.vue';
import ProductDetail from '@/pages/product/ProductDetail.vue';
import BrandTop from '@/pages/product/BrandTop.vue';
import ProductListByBrand from '@/pages/product/ProductListByBrand.vue';
import ProductListByCategory from '@/pages/product/ProductListByCategory.vue';
import ProductListByKeyword from '@/pages/product/ProductListByKeyword.vue';
import Privacy from '@/pages/static/Privacy.vue';
import UsageGuide from '@/pages/static/UsageGuide.vue';
import Favorite from '@/pages/user/Favorite.vue';
import Login from '@/pages/user/Login.vue';
import Mypage from '@/pages/user/Mypage.vue';
import Register from '@/pages/user/Register.vue';
import ShippingAddressEdit from '@/pages/user/ShippingAddressEdit.vue';
import ReviewInput from '@/pages/order/ReviewInput.vue';

// 使用 vue-router
Vue.use(VueRouter);

// 定义路由
const routes = [
  { path: '/', component: Home },
  { path: '/brand_top', component: BrandTop },
  { path: '/product_list_by_brand', component: ProductListByBrand },
  { path: '/product_list_by_category', component: ProductListByCategory },
  { path: '/product_list_by_keyword', component: ProductListByKeyword },
  { path: '/product_detail', component: ProductDetail },
  { path: '/cart', component: Cart },
  { path: '/register', component: Register },
  { path: '/login', component: Login },
  { path: '/order_confirm', component: OrderConfirm },
  { path: '/shipping_address_edit', component: ShippingAddressEdit },
  { path: '/mypage', component: Mypage },
  { path: '/favorite', component: Favorite },
  { path: '/order_history', component: OrderHistory },
  { path: '/order_detail', component: OrderDetail },
  { path: '/order_finish', component: OrderFinish },
  { path: '/usage_guide', component: UsageGuide },
  { path: '/privacy', component: Privacy },
  { path: '/review_input', component: ReviewInput },
];

// 创建路由实例
const router = new VueRouter({
  routes,  // 路由配置
  mode: 'history'  // 使用 history 模式
});

// 在 router 配置文件中
router.beforeEach((to, from, next) => {
  // 存储上一个页面的信息到 Vuex 或 sessionStorage
  sessionStorage.setItem('previousRoutePath', from.path);
  next();
});


export default router;
