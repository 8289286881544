<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar :key="refresh_navigationbar_key" />
    <!-- 标题栏 -->
    <TitleBar title="购买车" />

    <!-- 内容 -->
    <div v-if="cart_product_list.length > 0" class="padding-cu">
      <!-- 商品信息 -->
      <van-checkbox-group v-model="selected_product_sku_id_list" @change="checkProductChange">
        <div v-for="(item, index) in cart_product_list" :key="index" class="product-box">
          <van-checkbox :name="item.product_sku_id" shape="square"></van-checkbox>
          <img :src="item.thumbnail_image" alt="缩略图" class="margin-left-sm thumbnail-img" @click="toProductDetail(item.product_id)" />
          <div class="margin-left-sm flex flex-direction justify-between flex-1 text-lg">
            <div>
              {{
                item.product_title_cn
                  ? item.product_title_cn
                  : item.product_title_jp
              }}
            </div>
            <div class="margin-top-xs">
              {{
                item.spec_title_cn ? item.spec_title_cn : item.spec_title_jp
              }}
            </div>
            <div class="margin-top-xs text-bold">
              {{ item.selling_price_text }}日元
              <span class="text-df">
                (约<span class="text-gold">{{item.selling_price_rmb_text}}</span>人民币)
              </span>
            </div>
            <div v-if="item.stock - item.quantity < 0" class="margin-top-xxs text-df text-red">
              库存不足仅余{{ item.stock }}件
            </div>
            <div class="flex justify-between align-center margin-top-sm">
              <van-stepper v-model="item.quantity" min="1" :max="item.stock" button-size="22px" @change="selectQuantity(item.product_sku_id, item.quantity)" />
              <van-icon name="delete-o" size="22" class="text-xl text-grey delete-icon" @click="delProduct(item.product_sku_id)" />
            </div>
          </div>
        </div>
      </van-checkbox-group>

      <!-- 合计信息 -->
      <!-- 合計金額 -->
      <div class="total-summary">
        <div class="info-item">
          <span>商品总价</span>
          <span>{{ product_total_price_text }}日元 (约
            {{ product_total_price_rmb_text }}人民币)</span>
        </div>
        <div class="info-item">
          <span>运费</span>
          <span>{{ shipping_fee.price_text }}日元 (约
            {{ shipping_fee.price_rmb_text }}人民币)</span>
        </div>
        <div class="info-item total-amount">
          <span>合计</span>
          <span>{{ total_price_text }}日元 (约
            <span class="price-highlight">{{ total_price_rmb_text }}</span>人民币)</span>
        </div>
      </div>

      <!-- 按钮 -->
      <div class="margin-top">
        <form>
          <input v-if="login_status == 'MEMBER'" type="button" value="结账" class="input-button submit-after" @click="toOrderConfirm" />

          <template v-else>
            <input type="button" value="登录" class="input-button submit-after" @click="toLogin" />

            <input type="button" value="注册" class="input-button submit-after" @click="toRegister" />

            <input type="button" value="访客购买" class="input-button submit-after" @click="toGuestBuyBeforeCoupon" />
          </template>
        </form>
      </div>
    </div>
    <van-empty v-else :image="require('@/assets/images/logo.png')" />

    <!-- 优惠劵弹窗 -->
    <van-dialog v-model="show_coupon" :showConfirmButton="false">
      <div class="coupon-wrap">
        <div class="text-xl text-gold text-center">
          <van-icon name="balance-o" class="margin-right-xxs text-xxxl" />优惠券
        </div>
        <div class="margin-top-xl">
          注册会员后，将赠送<span class="text-gold text-xl">{{
            register_coupon.ammount_text
          }}</span>日元的优惠券。
        </div>
        <form>
          <input type="button" value="注册" class="input-button submit-after margin-top-xxl" @click="toRegister" />

          <input type="button" value="访客购买" class="input-button submit-after" @click="toGuestBuy" />
        </form>
      </div>
    </van-dialog>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import {
  checkCart,
  delCartProduct,
  getCartProduct,
  getCartProductByLocal,
  getRegisterCoupon,
  getShippingFee,
  guestRegister,
  updateCartProductQuantity,
} from "../../https/http";

// vant弹窗提示
import { Dialog, Toast } from "vant";
import util from "../../utils/util";

export default {
  name: "Cart",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      cart_product_list: [],
      product_total_price: 0,
      product_total_price_rmb: 0,
      shipping_fee: [],
      selected_product_sku_id_list: [], // 被选中的sku的id
      login_status: this.$globalData.login_status,
      register_coupon: [], //注册优惠劵
      show_coupon: false,
      refresh_navigationbar_key: 0, // 初始 key 值
    };
  },
  computed: {
    product_total_price_text() {
      return util.formatMoney(this.product_total_price.toFixed(0));
    },
    product_total_price_rmb_text() {
      return util.formatMoney(this.product_total_price_rmb.toFixed(0));
    },
    total_price_text() {
      return util.formatMoney((Number(this.product_total_price) + Number(this.shipping_fee.price)).toFixed(0));
    },
    total_price_rmb_text() {
      return util.formatMoney((Number(this.product_total_price_rmb) + Number(this.shipping_fee.price_rmb)).toFixed(0));
    },
  },
  created() {
    if (!!this.$globalData.login_status) {
      //登录状态 从数据库获取cart内容  TODO
      getCartProduct().then((res) => {
        this.cart_product_list = res.data;
        this.cart_product_list.forEach((item) => {
          this.selected_product_sku_id_list.push(item.product_sku_id);
        });
        this.computeTotalPrice();
      });
    } else {
      let cart = JSON.parse(localStorage.getItem("cart"));
      getCartProductByLocal({ cart }).then((res) => {
        this.cart_product_list = res.data;
        this.cart_product_list.forEach((item) => {
          this.selected_product_sku_id_list.push(item.product_sku_id);
        });
        this.computeTotalPrice();
      });
    }

    // 获取注册优惠劵
    getRegisterCoupon().then((res) => {
      this.register_coupon = res.data;
    });

    // 获取运费
    getShippingFee().then((res) => {
      this.shipping_fee = res.data;
    });
  },
  mounted() { },
  methods: {
    /**
     * 跳转到商品详情页
     */
    toProductDetail(product_id) {
      this.$router.push({
        path: "/product_detail",
        query: { product_id },
      });
    },
    /**
     * 选择的商品
     */
    checkProductChange() {
      // 计算总价
      this.computeTotalPrice();
    },
    /**
     * sku数量选择
     */
    selectQuantity(product_sku_id, quantity) {
      //更新购物车内商品数量
      this.updateLocalCartProductQuantity(product_sku_id, quantity);
      if (!!this.$globalData.login_status) {
        //登录状态 更新服务器购物车内商品数量
        this.updateRemoteCartProductQuantity(product_sku_id, quantity);
      }
      this.computeTotalPrice();
    },

    /**
     * 更新本地存储购物车商品数量
     */
    updateLocalCartProductQuantity(product_sku_id, quantity) {
      let cart = JSON.parse(localStorage.getItem("cart"));
      if (util.isEmpty(cart)) {
        return;
      }
      cart.forEach((item) => {
        if (item.product_sku_id === product_sku_id) {
          item.quantity = quantity;
        }
      });
      localStorage.setItem("cart", JSON.stringify(cart));
      this.cart_product_list.forEach((item) => {
        if (item.product_sku_id === product_sku_id) {
          item.quantity = quantity;
        }
      });
    },
    /**
     * 更新服务器购物车内商品数量
     */
    updateRemoteCartProductQuantity(product_sku_id, quantity) {
      updateCartProductQuantity({ product_sku_id, quantity }).then((res) => {
        this.cart_product_list = res.data;
      });
    },

    /**
     * 删除商品
     */
    delProduct(product_sku_id) {
      Dialog.confirm({
        title: "提示",
        message: "把该商品从购买车中删除吗？",
      })
        .then(() => {
          // 修改this.selected_product_sku_id_list
          this.selected_product_sku_id_list = this.selected_product_sku_id_list.filter((item) => {
            item !== product_sku_id
          });
          // 删除本地存储购物车商品
          this.delLocalCartProduct(product_sku_id);
          if (!!this.$globalData.login_status) {
            //登录状态 删除服务器存储购物车商品  TODO
            this.delRemoteCartProduct(product_sku_id);
          }
        })
        .catch(() => {
          // on cancel
        });
    },

    /**
     * 删除本地存储购物车商品
     */
    delLocalCartProduct(product_sku_id) {
      let cart = JSON.parse(localStorage.getItem("cart"));
      if (util.isEmpty(cart)) {
        return;
      }
      let result = cart.filter(
        (item) => item.product_sku_id !== product_sku_id
      );
      localStorage.setItem("cart", JSON.stringify(result));
      this.cart_product_list = this.cart_product_list.filter(
        (item) => item.product_sku_id !== product_sku_id
      );
      this.computeTotalPrice();
    },
    /**
     * 删除服务器存储购物车商品
     */
    delRemoteCartProduct(product_sku_id) {
      delCartProduct({ product_sku_id }).then((res) => {
        this.cart_product_list = res.data;
        this.computeTotalPrice();
      });
    },

    /**
     * 计算总价
     */
    computeTotalPrice() {
      const that = this;
      let product_total_price = 0;
      let product_total_price_rmb = 0;
      let cart_product_list = that.cart_product_list;
      let selected_product_sku_id_list = that.selected_product_sku_id_list;

      for (let i = 0; i < cart_product_list.length; i++) {
        if (
          selected_product_sku_id_list.includes(
            cart_product_list[i].product_sku_id
          )
        ) {
          product_total_price +=
            cart_product_list[i].selling_price *
            parseInt(cart_product_list[i].quantity);
          product_total_price_rmb +=
            cart_product_list[i].selling_price_rmb *
            parseInt(cart_product_list[i].quantity);
        }
      }
      that.product_total_price = product_total_price;
      that.product_total_price_rmb = product_total_price_rmb;
      that.$globalData.cart_num = cart_product_list.length; // 修改全局变量
      that.refresh_navigationbar_key += 1; // 更新 key，触发重新渲染
    },
    /**
     * 跳转注册页面
     */
    toRegister() {
      this.show_coupon = false;
      this.$globalData.after_login_path = "/cart";
      this.$router.push({ path: "/register" });
    },
    /**
     * 跳转登录页面
     */
    toLogin() {
      this.$globalData.after_login_path = "/cart";
      this.$router.push({ path: "/login" });
    },

    /**
     * 显示优惠劵前的访客购买
     */
    toGuestBuyBeforeCoupon() {
      if (this.selected_product_sku_id_list.length < 1) {
        Toast.fail("没有选择任何商品");
        return;
      }
      if (!util.isEmpty(this.register_coupon)) {
        this.show_coupon = true;
      } else {
        this.toGuestBuy();
      }
    },

    /**
     * 访客购买
     */
    toGuestBuy() {
      this.show_coupon = false;
      if (!!this.$globalData.login_status) {
        this.toOrderConfirm();
        return;
      }
      this.checkOrderProduct().then((res) => {
        if (res) {
          let values = {
            cart: JSON.parse(localStorage.getItem("cart")),
          };
          if (!!localStorage.getItem("cpno")) {
            values.cpno = localStorage.getItem("cpno");
          }
          if (!!localStorage.getItem("introducer")) {
            values.introducer = localStorage.getItem("introducer");
          }
          guestRegister(values).then((res) => {
            // 清除user_session_key
            localStorage.removeItem("user_session_key");
            sessionStorage.removeItem("user_session_key");
            sessionStorage.setItem("user_session_key", res.data);

            this.$globalData.login_status = "GUEST"; // 修改全局变量
            this.$router.push({ path: "/order_confirm" });
          });
        }
      });
    },

    /**
     * 跳转到注文确认页面
     */
    toOrderConfirm() {
      this.checkOrderProduct().then((res) => {
        if (res) {
          this.$router.push({ path: "/order_confirm" });
        }
      });
    },

    /**
     * check order product
     */
    checkOrderProduct() {
      if (this.selected_product_sku_id_list.length < 1) {
        Toast.fail("没有选择任何商品");
        return new Promise((resolve) => {
          resolve(false);
        });
      }
      let order_cart = [];
      let selected_product_sku_id_list = this.selected_product_sku_id_list;
      for (let i = 0; i < this.cart_product_list.length; i++) {
        if (
          selected_product_sku_id_list.includes(
            this.cart_product_list[i].product_sku_id
          )
        ) {
          let product = {};
          product.product_sku_id = this.cart_product_list[i].product_sku_id;
          product.quantity = this.cart_product_list[i].quantity;
          order_cart.push(product);
        }
      }

      //请求接口，检查购物车内商品是否有库存
      return checkCart({ order_cart }).then((res) => {
        if (res.data == "OK") {
          localStorage.setItem("order_cart", JSON.stringify(order_cart));
          return true;
        } else {
          Toast.fail(res.data);
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.product-box {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #5d6369;
  display: flex;
  align-items: center;
}

.product_desc {
  height: 130px;
}

.thumbnail-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.btn-margin-top {
  margin-top: 24px;
}

/deep/ .van-empty__image {
  width: 133px;
  height: 80px;
}

/deep/ .van-checkbox__label {
  color: #fff;
}

/deep/ .van-checkbox__icon--checked .van-icon {
  background: #fff;
  border-color: #fff;
  color: #333;
}
</style>
