<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar :title="brand_mst.title_cn" />

    <!-- 内容 -->
    <div class="padding-cu">
      <!-- product -->
      <div
        v-if="product_list.length > 0"
        class="flex flex-wrap product-wrap margin-top-xs"
      >
        <div v-for="(product, index) in product_list" :key="index">
          <ProductIntroColumn :product="product" />
        </div>
      </div>
      <van-empty v-else :image="require('@/assets/images/logo.png')" />
    </div>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import ProductIntroColumn from "../../components/ProductIntroColumn.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { getProductListByBrand } from "../../https/http";

export default {
  name: "ProductListByBrand",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
    ProductIntroColumn,
  },
  data() {
    return {
      brand_mst: {}, // 分类信息
      product_list: [], // product列表
    };
  },
  created() {
    let brand_id = this.$route.query.brand_id;
    getProductListByBrand(brand_id).then((res) => {
      this.brand_mst = res.data.brand_mst;
      this.product_list = res.data.product_list;
    });
  },
  mounted() {},
  methods: {
  
  },
};
</script>

<style scoped>
.product-wrap {
  gap: 0.53rem;
}

/deep/ .van-empty__image {
  width: 133px;
  height: 80px;
}

</style>
