<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="登录" />

    <!-- 内容 -->
    <div class="padding-cu margin-top-xl">
      <form>
        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="tel"
            id="phone_number"
            placeholder="phone_number"
            v-model="phone_number"
          />
          <label class="label" for="phone_number"> 手机号 </label>
        </div>

        <div class="input-wrapper">
          <input
            autocomplete="off"
            class="input"
            type="password"
            id="password"
            placeholder="password"
            v-model="password"
          />
          <label class="label" for="password"> 密码 </label>
        </div>

        <!-- 记住登录状态 -->
        <div class="margin-top-lg flex justify-center">
          <van-checkbox
            v-model="remeber_login_status"
            shape="square"
            checked-color="#fff"
            >记住登录状态</van-checkbox
          >
        </div>

        <!-- 登录按钮 -->
        <div class="margin-top">
          <!--入力前-->
          <input
            v-if="!phone_number || !password"
            type="button"
            value="登录"
            class="input-button submit-before"
          />
          <!--入力後-->
          <input
            v-else
            type="button"
            value="登录"
            class="input-button submit-after"
            @click="onSubmit"
          />
        </div>

        <!-- 忘记密码 -->
        <div class="margin-top-xl">
          <p class="paragraph">如果您忘记了密码，请联系客服。</p>
          <p class="paragraph">客服微信：IREY_jp</p>
          <div class="margin-top-sm text-center">
            <img src="../../assets/images/cs_qr.png" alt="客服微信">
          </div>
        </div>
      </form>
    </div>
    
    <!-- 优惠劵弹窗 -->
    <van-dialog v-model="show_coupon" :showConfirmButton="false">
      <div class="coupon-wrap">
        <div class="">感谢您的会员注册。</div>
        <div class="margin-top-xs">
          我们将赠送您<span class="text-gold text-xl">{{
            register_coupon.ammount_text
          }}</span
          >日元的优惠券。
        </div>
        <div class="margin-top-xs">
          请登录并在购买商品时输入以下优惠券代码。
        </div>

        <div class="margin-top-xxl text-center text-bold text-df">
          优惠券代码
        </div>
        <div class="margin-top-sm text-center text-bold text-xxxl">
          {{ register_coupon.coupon_code }}
        </div>
        <form>
          <input
            type="button"
            value="登录"
            class="input-button submit-after margin-top-xxl"
            @click="closeCoupon"
          />
        </form>
      </div>
    </van-dialog>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import {
  getRegisterCoupon,
  uploadLocalCart,
  userLogin,
} from "../../https/http";

// vant弹窗提示
import { Toast } from "vant";

export default {
  name: "Login",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      phone_number: "",
      password: "",
      remeber_login_status: true,
      register_coupon: [], //注册优惠劵
      show_coupon: false,
    };
  },
  created() {
    let coupon_id = this.$route.query.coupon_id;
    if (!!coupon_id) {
      // 获取注册优惠劵
      getRegisterCoupon({ coupon_id }).then((res) => {
        this.register_coupon = res.data;
        this.show_coupon = true;
      });
    }
  },
  mounted() {},
  methods: {
    onSubmit() {
      let values = {
        phone_number: this.phone_number,
        password: this.password,
        introducer: localStorage.getItem("introducer"),
      };
      userLogin(values).then((res) => {
        if (res.data.status == "NG") {
          Toast.fail(res.data.msg);
        }
        if (res.data.status == "OK") {
          Toast.success(res.data.msg);
          // 清除user_session_key
          localStorage.removeItem("user_session_key");
          sessionStorage.removeItem("user_session_key");
          if (this.remeber_login_status) {
            localStorage.setItem("user_session_key", res.data.user_session_key);
          } else {
            sessionStorage.setItem(
              "user_session_key",
              res.data.user_session_key
            );
          }
          this.$globalData.login_status = "MEMBER"; // 修改全局变量
          this.uploadLocalCart();
          setTimeout(() => {
            this.$router.push({ path: this.$globalData.after_login_path });
            window.location.reload();
          }, 1000);
        }
      });
    },
    /**
     * 把本地购物车上传到服务器
     */
    uploadLocalCart() {
      let cart = JSON.parse(localStorage.getItem("cart"));
      if (!cart) {
        return;
      }
      uploadLocalCart({ cart }).then((res) => {
        
      });
    },
    /**
     * 关闭弹窗
     */
    closeCoupon() {
      this.show_coupon = false;
    },
  },
};
</script>

<style scoped>
/deep/ .van-checkbox__label {
  color: #fff;
}

/deep/ .van-checkbox__icon--checked .van-icon {
  color: #333;
}
.paragraph {
  line-height: 1.5em;
}
</style>
