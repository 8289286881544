<template>
  <div class="navigation padding-cu">
    <div>
      <div class="menu-icon" @click="toggleMenu">☰</div>
      <!-- 下拉菜单，当 showMenu 为 true 时显示 -->
      <div v-if="showMenu" class="dropdown-menu">
        <ul>
          <li v-if="this.$globalData.login_status !== 'MEMBER'" @click="toRegister">会员注册</li>
          <li @click="toUsageGuide">利用指南</li>
          <li @click="toPrivacy">隐私政策</li>
          <li v-if="this.$globalData.login_status === 'MEMBER'" @click="logout">退出登录</li>
        </ul>
      </div>
    </div>
    <img src="../assets/images/logo.png" class="logo" @click="toHome" />
    <div>
      <img
        src="../assets/images/icon/icon-user.svg"
        class="icon"
        @click="toMypage"
      />
      <van-badge :content="cart_num">
        <img
          src="../assets/images/icon/icon-cart.svg"
          class="icon margin-left-xs"
          @click="toCart"
        />
      </van-badge>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  props: {},
  data() {
    return {
      showMenu: false, // 控制下拉菜单显示状态
      cart_num: 0,
    };
  },
  created(){
    this.cart_num = this.$globalData.cart_num
  },
  methods: {
    /**
     * 会员注册
     */
    toRegister() {
      this.$router.push({ path: "/register" });
    },
    toHome() {
      this.$router.push({ path: "/" });
    },
    toCart() {
      this.$router.push({ path: "/cart" });
    },
    toMypage() {
      if (this.$globalData.login_status === "MEMBER") {
        this.$router.push({ path: "/mypage" });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu; // 切换菜单显示状态
    },
    // 利用指南
    toUsageGuide() {
      this.$router.push({ path: "/usage_guide" });
    },
    // 隐私政策
    toPrivacy() {
      this.$router.push({ path: "/privacy" });
    },
    // 退出登录
    logout() {
      // 清localStorage和sessionStorage
      localStorage.removeItem("user_session_key");
      sessionStorage.removeItem("user_session_key");
      localStorage.removeItem("cart");
      localStorage.removeItem("order_cart");
      localStorage.removeItem("cpno");
      localStorage.removeItem("introducer");
      sessionStorage.removeItem("previousRoutePath");
      sessionStorage.removeItem("use_coupon");

      // 去首页，然后刷新
      if (this.$router.currentRoute.path != "/") {
        this.$router.push("/").then(() => {
          window.location.reload();
        });
      } else {
        window.location.reload();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navigation {
  height: 130px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: linear-gradient(to bottom right, #2e2e2e, #5d6369);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.van-icon {
  cursor: pointer;
}

.dropdown-menu {
  background-color: #4d5156;
  border: 1px solid #ccc;
  position: absolute;
  top: 130px;
  left: 0px;
  width: 750px;
  z-index: 1000;
}

.dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li {
  padding: 25px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.dropdown-menu li:last-child {
  border-bottom: none;
}

.dropdown-menu li:hover {
  background-color: #ccc;
}

.logo {
  height: 100%;
}

.menu-icon {
  font-size: 50px;
}

.icon {
  width: 50px;
  height: 50px;
}
</style>
