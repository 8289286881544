<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />

    <!-- 标题栏 -->
    <TitleBar title="利用指南" />

    <!-- 内容 -->
    <div class="padding-cu margin-top-xl">
      <p class="paragraph">您好！为了帮助您更好地选购海外商品，请在购物前仔细阅读并理解本《购物指南》的所有内容，并根据自己的风险承受能力做出判断。请您在确认本指南内容后，再进行下单购买。</p>
      <p class="paragraph text-bold">1. 商品质量与安全：您所购买的商品符合原产地有关质量、安全、卫生、环保、标识等标准或技术规范要求，但可能与我国标准存在差异。您需要自行承担相关风险。</p>
      <p class="paragraph text-bold">2. 商品标签：您所购买的商品直接购自境外，可能无中文标签。您可以通过查看商品详情页或联系客服获取商品中文电子标签。
      </p>
      <p class="paragraph text-bold">3. 商品使用限制：您所购买的商品仅限于个人自用，不得进行二次销售。请遵守相关法律法规规定。</p>
      <p class="paragraph text-bold">4. 海外购物流程：由于商品来自境外，购买过程可能涉及较长的物流周期，请您耐心等待。如有疑问，请联系客服咨询。</p>
      <p class="paragraph text-bold">5. 关税及税费：购买进口商品可能需要支付关税及其他税费。请在购买前了解相关税费政策，并在支付时一并结算。</p>
      <p class="paragraph text-bold">6. 售后服务：由于商品来自境外，售后服务可能受限。建议您在购买前详细了解商品信息，并保留好购买凭证以便日后维权。</p>
      <p class="paragraph text-bold">7. 风险提示：购买境外商品存在一定的风险，包括但不限于商品质量问题、物流延迟、海关扣押等情况。请您在购买前充分了解相关风险，并谨慎下单。</p>
      <p class="paragraph text-bold">8. 身份信息验证：为确保顺利通关，请您提供真实完整的身份信息和收货信息。我们承诺保护您的个人信息安全。</p>
      <p class="paragraph text-bold">9. 法律责任：请您遵守相关法律法规，不侵犯他人权益。如遇问题，请配合相关部门处理，并承担相应的法律责任。</p>
      <p class="paragraph">
        再次感谢您选择我们的境外商品服务，我们将竭诚为您提供优质的服务。祝您购物愉快！
      </p>
    </div>

    <!-- 尾部 -->
    <Footer />

  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口

// vant弹窗提示

export default {
  name: "UsageGuide",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() { },
  methods: {
  },
};
</script>

<style scoped>
.paragraph {
  line-height: 1.5em;
  margin-bottom: 1em;
}
</style>
