<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />

    <!-- 标题栏 -->
    <TitleBar title="隐私政策" />

    <!-- 内容 -->
    <div class="padding-cu margin-top">
      <p class="main-title">用户协议和隐私政策</p>
      <p class="sub-title">隐私</p>
      <p class="paragraph">查看和访问本网站或在本网站上购买任何产品，即表示您已阅读并理解本隐私政策的全文，且您同意本隐私政策的所有条款。如果您不同意本隐私政策的所有内容，您的唯一且独有的补救措施就是立即停止使用本网站。</p>
      <p class="paragraph">我们尊重本网站访问者的隐私。您与我们之间的隐私政策（"隐私政策"）旨在告知您我们关于收集、使用和披露您通过网站提交给我们的任何个人信息和匿名信息的政策和行为规范。</p>
      <p class="paragraph">"个人信息"是指您的个人识别信息，如您的姓名、地址、电子邮件地址，或手机号码、付款和账单信息，以及与上述内容相关的其他非公开信息。 "匿名信息"是指与您的个人信息无关或无联系的信息；匿名信息不允许透露个人身份。</p>
      <p class="sub-title">用户同意</p>
      <p class="paragraph">通过本网站提交个人信息，即表示您同意本隐私政策的条款并明确同意按照本隐私政策对您的个人信息进行处理。</p>
      <p class="sub-title">收集个人信息</p>
      <p class="paragraph">从您那里收集的个人信息可帮助我们了解网站访客、我们的客户和潜在客户。 通过使用这些信息，我们能够更好地优化我们产品和服务的功能和性能并提供更好的支持，从而为您提供更多信息和机会，并通知您本隐私政策 [以及网站使用条款] 的变更情况。</p>
      <p class="sub-title">您向我们提供的个人信息</p>
      <p class="paragraph">我们收集的个人信息是您通过本网站自愿提供给我们的。</p>
      <p class="paragraph">如果您在本网站上购买产品，我们会收集您向我们提供的以下信息： 您的名字和姓氏、电子邮件地址、邮箱地址以及账单地址（如与您的邮箱地址不同）。</p>
      <p class="paragraph">当您通过发送电子邮件联系我们时，我们会收集您的姓名、电子邮件地址和电子邮件中包含的信息。</p>
      <p class="paragraph">当您回复我们的客户满意度调查时，我们会收集您的姓名和电子邮件地址。</p>
      <p class="paragraph">如果您选择订阅我们的产品更新信息，我们会收集您的姓名和电子邮件地址。</p>
      <p class="sub-title">凭借技术收集个人信息</p>
      <p class="paragraph">当 您浏览本网站时，某些信息可能也会被被动收集，包括您的网络通信协议地址（"IP 地址"）、浏览器类型、域名、访问时间以及操作系统。 我们也会使用 "Cookie" 和包括统一资源定位器 (URL) 在内的导航数据，来收集您的访问日期和时间的相关信息，以及您搜索和浏览的信息。 收集这些类型的信息可以让我们的产品和服务为您提供更有针对性的帮助。在我们向您发送的电子邮件中，有的会包含 "点击 URL"，尽可能为您提供方便。当您点击这些 URL 时，您将通过我们的网络服务器到达您的目标网站。通过追踪点击率，能帮助我们确定您对特定主题的兴趣，还能帮助我们衡量发送客户通信的有效性。</p>
      <p class="paragraph">"IP 地址" 是在您使用网络时向您的电脑自动分配的数字。在某些情况下，您的 IP 地址在浏览不同会话时是保持不变的；但是如果您使用的是消费者网络访问提供商，您的 IP 地址在不同会话时可能不同。 我们仅追踪和会话 cookie 相连的 IP 地址，用来分析我们网站的流量情况。</p>
      <p class="paragraph">"Cookie" 是在您浏览一个网站时，网站向您的电脑硬盘驱动器发送的一个小型信息片段。 我们可能使用会话 Cookie（一旦关闭网页浏览器便会失效）和持久性 Cookie（会一直保存在您的电脑上直到您删除它们），从而向您提供更加私人化和更具互动性的网站体验。 按照网络浏览器帮助文件指南，您就能删除持久性 Cookie。 如果您选择禁用 Cookie，网站的某些区域可能不会正常工作。</p>
      <p class="sub-title">从其他资源得到的个人信息</p>
      <p class="paragraph">我们可能通过包括邮件、电话或传真在内的其他资源获得您的个人信息。 我们可能将这些信息与我们已通过本网络收集的您的信息合并在一起，从而改进我们提供的产品和服务。</p>
      <p class="sub-title">信息使用</p>
      <p class="paragraph">如果您选择接收我们发送的以下通信，您提交给我们的个人信息可能被用于回复您的请求、向您发送新闻通讯、一般的信息公告，以及与我们或我们的产品相关的其他一般信息，或帮助我们为您提供更好的服务。我们的客户一直拥有随时退订的权利，可以拒绝接收我们发送的产品更新信息。在我们发送给您的每封电子邮件底部，您都有机会选择停止接收我们发送的更多通信。</p>
      <p class="paragraph">如果您不接收我们的通信，除非您在我们的网站上订购产品，并且我们出于某个原因不能处理您的订单，否则我们将不会联系您。在这种情况下，我们可能联系您，要求您提供额外的或正确的信息以完成对您的产品订单的处理。</p>
      <p class="paragraph">按照下方描述的详情，为了完成我们的服务，我们可能会向第三方供货商披露您的个人信息。 我们也可能会使用您的个人信息，向您提供网站变更和更新有关的信息，除非或直至您要求不再接收我们发送的此等通信。</p>
      <p class="paragraph">我们可能根据您的个人信息创建匿名信息，匿名信息里面不包括可识别您个人身份的信息（如您的姓名）。 我们可能利用匿名信息来分析请求模式和使用模式，从而提高我们的产品和服务。 我们保留自行选择向第三方使用和披露匿名信息的权利，包括用于内部业务分析，以及向我们的各个合作方提供汇总统计数据的权利。</p>
      <p class="sub-title">通过产品销售收集的个人信息</p>
      <p class="paragraph">如果您通过我们的网站购买任何可购产品，您可能会受附加的销售条款和条件的限制，并需获得相关许可证，才有权使用该产品中嵌入的任何软件（如有）。 这些附加条款和条件或许可证将管理您对此类产品所享有的权利。</p>
      <p class="sub-title">反馈</p>
      <p class="paragraph">如果您在本网站上向我们提供反馈，则在不透露您个人信息的前提下，我们可以将这些反馈用于任何目的。 我们将收集此类通信中包含的任何信息，并根据本隐私政策处理此类通信中的个人信息。</p>
      <p class="sub-title">个人信息披露</p>
      <p class="paragraph">除非本隐私政策另有说明，否则我们通常不会向第三方交换、出租或共享您的个人信息。除非您提出要求，否则我们将不会披露账单信息。</p>
      <p class="paragraph">但是，我们可能向第三方共享您的个人信息以处理付款，或帮助您访问本网站。除非提供我们需要的服务，否则这些第三方不得使用您的个人信息，且他们使用您的个人信息的行为受到相关保密协议的约束。您明确同意向我们的承包商和第三方服务提供商共享您的个人信息，但仅限用于向您提供我们的产品和服务之目的。</p>
      <p class="paragraph">有时我们可能会拥有母公司、子公司、合资公司，或者其他与我们共同受控于其他公司的公司（以下统称为 "附属公司"）。 目前我们不会与我们的附属公司共享您的个人信息；但是将来如果我们要与这些附属公司共享您的部分或全部个人信息，我们会要求他们遵守本隐私政策。</p>
      <p class="paragraph">如果我们的公司或资产被其他公司收购，该公司将拥有我们收集的个人信息，且该公司要承担本隐私政策中与您的个人信息相关的权利和义务。</p>
      <p class="paragraph">无论您做出与您的个人信息相关的任何选择（如下方所述），在以下情况中，我们都可能披露您的个人信息：(1) 我们真诚相信这种披露是必要的，以便 (a) 遵守相关法律或回应法院指令，或者 (b) 保护或捍卫我们或第三方的权利或资产；或 (2) 用于与调查和预防欺诈有关的活动。</p>
      <p class="sub-title">其他网站</p>
      <p class="paragraph">我们会向您提供任何其他网站或地址的链接，这样做旨在为您提供方便，不表示我们认可这些网站或地址或其内容。 我们不会控制、审查这些外部网站或其内容，亦不对其承担责任。 请注意，我们的隐私政策的条款并不适用于这些外部网站。</p>
      <p class="sub-title">安全性</p>
      <p class="paragraph">我们会通过商业上合理的努力来保护您个人信息的安全，使用行业标准的安全技术和程序，保护您的个人信息免受未授权的访问、使用或披露。 我们会保护您通过物理、电子和管理流程向我们发送的数据。 当您使用网络时，我们强烈建议您采取每一项预防措施来保护您的个人信息。 其中一个方法就是确保您使用的是安全的网页浏览器。我们的电子商务网站使用行业标准的安全套接层 (SSL) 加密技术，对您提交个人信息的所有电子商务网页进行加密。 为防患于未然，请确保您使用的是最新版本的启用 SSL 的浏览器。 这些浏览器在您通过网络传输包括您的信用卡信息在内的个人信息时，能够提供更好的保密性。</p>
      <p class="paragraph">除非本隐私政策另有规定，否则对于电子数据库中的个人信息，我们仅限组织中出于业务需求而需要访问这些信息的人（包括员工和承包商）进行访问。</p>
      <p class="paragraph">即使我们已采取措施防止您的个人信息被未授权人员截取、访问、使用或披露，但我们仍然无法完全消除与个人信息相关的安全风险。</p>
      <p class="sub-title">联系我们</p>
      <p class="paragraph">如果您对本隐私政策有任何疑问或疑虑,或者想注销用户，请通过客服微信：IREY_jp联系我们。</p>
      <p class="sub-title">隐私政策更新</p>
      <p class="paragraph">本隐私政策可能随时修订，具体时间由我们单方决定。 我们将向您提供的电子邮件地址发送电子邮件，以告知您本隐私政策的重要更改，而且我们会在网站上发布本隐私政策的任何更改。 但是我们建议您在每次使用我们的网站时都要阅读本隐私政策，以防您漏掉我们有关本隐私政策变更的通知。 如果您不同意这些更改，您必须立即停止使用本网站。在收到更改通知后继续使用本网站，即表示您知晓这些更改并同意受到这些更改后条款和条件的约束。</p>
    </div>

    <!-- 尾部 -->
    <Footer />

  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口

// vant弹窗提示

export default {
  name: "Privacy",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() { },
  methods: {
  },
};
</script>

<style scoped>
.main-title {
  font-size:large;
  font-weight: bold;
  margin-bottom: 1em;
}
.sub-title {
  line-height: 1.5em;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 0.25em;
}
.paragraph {
  line-height: 1.5em;
  margin-bottom: 0.25em;
}
</style>
