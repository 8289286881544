<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="发表评论" />

    <!-- 内容 -->
    <div class="padding-cu">
      <div class="product-box">
        <img
          :src="order_detail_dat.thumbnail_image"
          alt="缩略图"
          class="thumbnail-img"
        />
        <div
          class="margin-left-sm flex flex-direction justify-between flex-1 padding-right text-sm"
        >
          <div>
            <div class="product-title">
              {{
                order_detail_dat.product_title_cn
                  ? order_detail_dat.product_title_cn
                  : order_detail_dat.product_title_jp
              }}
            </div>
            <div class="margin-top-xs product-title">
              {{
                order_detail_dat.spec_title_cn
                  ? order_detail_dat.spec_title_cn
                  : order_detail_dat.spec_title_jp
              }}
            </div>
          </div>
        </div>
      </div>

      <form>
        <div class="margin-top">
          <label class="label-title">评分</label>
          <van-rate
            v-model="rating"
            :size="25"
            color="#E3D17D"
            void-icon="star"
            void-color="#eee"
          />
        </div>
        <div class="margin-top">
          <label class="label-title">评论</label>
          <textarea
            class="comment-textarea"
            rows="5"
            v-model="comment"
          ></textarea>
        </div>

        <div class="margin-top-lg">
          <!--入力前-->
          <input
            v-if="!comment"
            type="button"
            value="发布"
            class="input-button submit-before"
          />

          <!--入力後-->
          <input
            v-else
            type="button"
            value="发布"
            class="input-button submit-after"
            @click="onSubmit"
          />

          <!--返回-->
          <input
            type="button"
            value="返回"
            class="input-button submit-before"
            @click="goBack"
          />
        </div>
      </form>
    </div>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { getOrderDetailDatById, createReview } from "../../https/http";

// vant弹窗提示
import { Dialog, Toast } from "vant";

export default {
  name: "ReviewInput",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      order_detail_dat: [],
      rating: 5,
      comment: "",
    };
  },
  computed: {},
  created() {
    let order_detail_id = this.$route.query.order_detail_id;
    getOrderDetailDatById({ order_detail_id }).then((res) => {
      this.order_detail_dat = res.data.order_detail_dat;
    });
  },
  mounted() {},
  methods: {
    onSubmit() {
      Dialog.confirm({
        title: "提示",
        message: "确认发表对该商品的评论吗？",
      })
        .then(() => {
          createReview({
            order_detail_id: this.order_detail_dat.order_detail_id,
            rating: this.rating,
            comment: this.comment,
          }).then((res) => {
            if (res.data.status == "OK") {
              Toast.success(res.data.msg);
              setTimeout(() => {
                this.goBack()
              }, 1500);
            }
          });
        })
        .catch((e) => {
          // on cancel
        });
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.label-title {
  display: block;
  margin-bottom: 15px;
}
.comment-textarea {
  all: unset;
  width: 100%;
  border-bottom: 1px solid #5d6369;
}

.comment-textarea:focus {
  border-bottom: 1px solid #ffffff;
}

.product-box {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
}

.thumbnail-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.product-title {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
