<template>
  <div class="product-box">
    <img :src="product.thumbnail_image" alt="缩略图" class="thumbnail-img" />
    <div
      class="margin-left-sm flex flex-direction justify-between flex-1 padding-right text-sm"
    >
      <div>
        <div class="product-title">
          {{
            product.product_title_cn
              ? product.product_title_cn
              : product.product_title_jp
          }} 
        </div>
        <div class="margin-top-xs product-title">
          {{
            product.spec_title_cn
              ? product.spec_title_cn
              : product.spec_title_jp
          }}
        </div>
        <div class="margin-top-sm float-right">
          <form>
            <input v-if="product.is_reviewed"
              type="button"
              value="已评论"
              class="input-button submit-before btn-small"
            />
            <input v-else
              type="button"
              value="评论"
              class="input-button submit-after btn-small"
              @click="toReviewInput(product.order_detail_id)"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductReview",
  props: {
    product: Object,
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 订单评论
     */
    toReviewInput(order_detail_id) {
      this.$router.push({
        path: "/review_input",
        query: { order_detail_id },
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.product-box {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  color: black;
}

.thumbnail-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.product-title {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow:ellipsis;
}
</style>
