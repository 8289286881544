<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="确认订单" />

    <!-- 内容 -->
    <div class="padding-cu">
      <!-- 商品信息 -->
      <ProductIntroRow
        class="border-bottom-gray"
        v-for="(product, index) in order_product_list"
        :key="index"
        :product="product"
      />

      <!-- 配送情報 -->
      <div class="shipping-info">
        <div class="padding-top-xs padding-bottom text-gold">
          为了您能顺利收到货物，请您认真填写收货人信息和微信号。
        </div>

        <div class="shipping-header">
          <span class="icon-left">
            <img src="../../assets/images/icon/icon-map.svg" alt="Map Icon" />
          </span>
          <div
            v-if="
              !user_shipping_address.consignee_full_name ||
              !user_shipping_address.consignee_phone_number
            "
            class="address-details text-gray"
            @click="toShippingAddressEdit"
          >
            请填写收货人信息
          </div>
          <div v-else class="address-details">
            <p>
              收货人：{{ user_shipping_address.consignee_full_name }}
              {{ user_shipping_address.consignee_phone_number }}
            </p>
            <p>
              收货地址：{{
                user_shipping_address.province +
                user_shipping_address.city +
                user_shipping_address.district +
                user_shipping_address.address
              }}
            </p>
          </div>
          <span class="icon-right" @click="toShippingAddressEdit"
            ><img src="../../assets/images/icon/icon-edit.svg" alt="Edit Icon"
          /></span>
        </div>
      </div>

      <!-- 优惠劵 -->
      <div
        v-if="!!user_register_coupon.coupon_code && order_info.product_price_total >
          user_register_coupon.ammount"
        class="shipping-info margin-top-lg"
      >
        <div class="shipping-header">
          <span class="icon-left"
            ><van-icon name="balance-o" class="text-xxxl text-gold"
          /></span>
          <div v-if="!use_coupon" class="address-details text-gray">
            请填写优惠券代码
          </div>
          <div v-else class="address-details">
            <p>优惠券代码: {{ user_register_coupon.coupon_code }}</p>
          </div>
          <span class="icon-right" @click="selectCoupon">
            <img src="../../assets/images/icon/icon-edit.svg" alt="Edit Icon" />
          </span>
        </div>
      </div>

      <!-- 微信号 -->
      <form>
        <div class="shipping-info margin-top-xxl">
          <div class="shipping-header">
            <span class="icon-left"
              ><van-icon name="chat-o" class="text-xxxl text-gold"
            /></span>
            <div class="flex-shrink-0">微信号:</div>
            <div class="input-wrapper">
              <input
                autocomplete="off"
                class="input"
                type="text"
                id="wxid"
                placeholder="wxid"
                v-model="wxid"
                ref="inputFieldWxid"
              />
            </div>
            <div class="address-details">
              <span class="icon-right" @click="editWxid">
                <img
                  src="../../assets/images/icon/icon-edit.svg"
                  alt="Edit Icon"
                />
              </span>
            </div>
          </div>
        </div>
      </form>

      <!-- 合計金額 -->
      <div class="total-summary">
        <div class="info-item text-lg">
          <span>商品总价</span>
          <span
            >{{ order_info.product_price_total_text }}日元 (约
            {{ order_info.product_price_total_rmb_text }}人民币)</span
          >
        </div>
        <div class="info-item text-lg">
          <span>运费</span>
          <span
            >{{ order_info.shipping_fee_price_text }}日元 (约
            {{ order_info.shipping_fee_price_rmb_text }}人民币)</span
          >
        </div>
        <div v-if="use_coupon" class="info-item">
          <span>优惠劵</span>
          <span
            >-{{ user_register_coupon.ammount_text }}日元 (约 -{{
              user_register_coupon.ammount_rmb_text
            }}人民币)</span
          >
        </div>
        <div class="info-item total-amount">
          <span>合计</span>
          <span
            >{{
              use_coupon
                ? order_info.price_total_after_coupon_text
                : order_info.price_total_text
            }}日元 (约
            <span class="price-highlight">{{
              use_coupon
                ? order_info.price_total_after_coupon_rmb_text
                : order_info.price_total_rmb_text
            }}</span
            >人民币)</span
          >
        </div>
      </div>

      <!-- 按钮 -->
      <form class="margin-top-xl">
        <van-radio-group v-model="pay_type" class="flex justify-center">
          <van-radio v-if="is_wechat_browser" name="wechatpay_browser"
            >WeChatPay</van-radio
          >
          <van-radio v-if="!is_wechat_browser" name="wechatpay"
            >WeChatPay</van-radio
          >
          <van-radio
            v-if="!is_wechat_browser"
            name="alipayplus"
            class="margin-left"
            >Alipay+</van-radio
          >
        </van-radio-group>
        <input
          v-if="
            !user_shipping_address.consignee_full_name ||
            !user_shipping_address.consignee_phone_number ||
            !wxid
          "
          type="button"
          value="支付"
          class="input-button submit-before"
        />

        <!--入力後-->
        <input
          v-else
          type="button"
          value="支付"
          class="input-button submit-after"
          @click="onPay"
        />
      </form>
    </div>

    <!-- 优惠劵弹窗 -->
    <van-dialog
      v-model="show_coupon"
      :showConfirmButton="false"
      closeOnClickOverlay
    >
      <div class="coupon-wrap">
        <div class="text-center">
          可以使用<span class="text-gold text-xl">{{
            user_register_coupon.ammount_text
          }}</span
          >日元的优惠券。
        </div>

        <div class="margin-top-xxl text-center text-bold text-df">
          优惠券代码
        </div>
        <div class="margin-top-sm text-center text-bold text-xxxl">
          {{ user_register_coupon.coupon_code }}
        </div>
        <form>
          <input
            v-if="!use_coupon"
            type="button"
            value="使用优惠券"
            class="input-button submit-after margin-top-xxl"
            @click="useCoupon"
          />

          <input
            v-else
            type="button"
            value="暂不使用"
            class="input-button submit-after margin-top"
            @click="nonuseCoupon"
          />
        </form>
      </div>
    </van-dialog>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import ProductIntroRow from "../../components/ProductIntroRow.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { confirmOrder, createOrder } from "../../https/http";

// vant弹窗提示
import { Toast } from "vant";
import util from "../../utils/util";

export default {
  name: "OrderConfirm",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
    ProductIntroRow,
  },
  data() {
    return {
      order_product_list: [],
      order_info: [],
      user_shipping_address: [],
      wxid: "",
      user_register_coupon: [],
      show_coupon: false,
      use_coupon: false,
      is_wechat_browser: this.$globalData.is_wechat_browser,
      pay_type: this.$globalData.is_wechat_browser
        ? "wechatpay_browser"
        : "wechatpay",
    };
  },
  created() {
    let order_cart = JSON.parse(localStorage.getItem("order_cart"));

    confirmOrder({ order_cart }).then((res) => {
      this.order_product_list = res.data.order_product_list;
      this.order_info = res.data.order_info;
      this.user_shipping_address = res.data.user_shipping_address;
      this.wxid = res.data.user_info.wxid;
      this.user_register_coupon = res.data.user_register_coupon;
      // 限制如果是从shipping_address_edit页面返回的则不在显示弹窗且商品总价小于优惠劵价格也不显示弹窗
      if (
        !util.isEmpty(this.user_register_coupon) &&
        this.order_info.product_price_total >
          this.user_register_coupon.ammount &&
        sessionStorage.getItem("previousRoutePath") !== "/shipping_address_edit"
      ) {
        this.show_coupon = true;
      }
      // 记录是否已选择了优惠劵
      if (
        !util.isEmpty(this.user_register_coupon) &&
        sessionStorage.getItem("use_coupon") == "true"
      ) {
        this.use_coupon = true;
      }
    });
  },
  mounted() {},
  methods: {
    toShippingAddressEdit() {
      sessionStorage.setItem("use_coupon", this.use_coupon);
      this.$router.push({ path: "/shipping_address_edit" });
    },

    //支付
    onPay() {
      let order_cart = JSON.parse(localStorage.getItem("order_cart"));
      let wxid = this.wxid;
      let user_register_coupon_id = this.use_coupon
        ? this.user_register_coupon.id
        : null;

      let pay_type = this.pay_type;
      createOrder({ order_cart, wxid, user_register_coupon_id, pay_type }).then(
        (res) => {
          if (res.data.status == "NG") {
            Toast.fail(res.data.msg);
          }
          if (res.data.status == "OK") {
            localStorage.removeItem("cart");
            localStorage.removeItem("order_cart");
            this.$globalData.cart_num = 0; // 修改全局变量
            window.location.href = res.data.url;
          }
        }
      );
    },

    /**
     * 使用优惠劵
     */
    useCoupon() {
      this.use_coupon = true;
      this.show_coupon = false;
    },

    /**
     * 不使用优惠劵
     */
    nonuseCoupon() {
      this.use_coupon = false;
      this.show_coupon = false;
    },

    /**
     * 选择优惠劵
     */
    selectCoupon() {
      this.show_coupon = true;
    },

    /**
     * 编辑微信号
     */
    editWxid() {
      this.$refs.inputFieldWxid.focus();
    },
  },
};
</script>

<style scoped>
/* 発送情報 */
.shipping-info {
  margin-top: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #fff;
}

.shipping-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.2rem;
}

.icon-left,
.icon-right {
  display: flex;
  align-items: center;
}

.icon-left img,
.icon-right img {
  width: 40px;
  height: 40px;
}

.address-details {
  flex: 1;
  color: #fff;
}

.address-details p {
  margin: 0;
  line-height: 1.5;
}

/deep/ .van-radio__icon--checked .van-icon {
  background-color: inherit;
  border-color: #fff;
}
/deep/ .van-radio__label {
  color: #fff;
}

form .input-wrapper {
  margin-top: initial;
}
form .input-wrapper .input {
  border-bottom: none;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
</style>
