// is emtpy
const isEmpty = str => {
  if (typeof (str) === "object" || typeof (str) === "array") {
    for (var key in str) {
      return false
    }
    return true
  }

  return str === undefined || str === '' || str === null

}

const formatMoney = (num) => {
  return String(num).replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,');
}
const checkPhone = (tel) => {
  if (isEmpty(tel)) {
    return false
  }
  let reg_cn = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  let reg_jp = /^(090|080|070|060)\d{8}$/
  if (reg_cn.test(tel) || reg_jp.test(tel)) {
    return true
  }
  return false
}
export default {
  isEmpty,
  formatMoney,
  checkPhone
}