<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="支付结果" />

    <!-- 内容 -->
    <div class="padding-cu">
      <div
        v-if="order_dat.status == 'IN_PAYMENT'"
        class="margin-top-lg flex justify-between align-center"
      >
        <div class="text-xxxl text-center">支付结果处理中，请稍后刷新</div>
        <form>
          <input
            type="button"
            value="刷新"
            class="input-button submit-after btn-refresh"
            @click="refreshOrder"
          />
        </form>
      </div>
      <div v-else>
        <h1 class="margin-top-lg text-xxxl text-center">
          <template v-if="order_dat.status == 'CANCELED'"> 支付取消 </template>
          <template v-else> 支付成功 </template>
        </h1>
        <!-- 订单信息 -->
        <div class="margin-top">
          <div>
            <span class="title-box">订单号:</span>
            <span>{{ order_dat.order_no }}</span>
          </div>

          <div class="margin-top-sm">
            <span class="title-box">创建时间:</span>
            <span>{{ order_dat.registration_date }}</span>
          </div>

          <div class="margin-top-sm">
            <span class="title-box">金额:</span>
            <span
              >{{ order_dat.total_text }}日元(约<span
                class="text-gold text-xs"
                >{{ order_dat.total_rmb_text }}</span
              >人民币)</span
            >
          </div>
        </div>
      </div>

      <form class="margin-top-xxl">
        <input
          type="button"
          value="回到首页"
          class="input-button submit-after"
          @click="toHome"
        />
      </form>
    </div>
    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { getOrderDetail } from "../../https/http";

// vant弹窗提示
export default {
  name: "OrderFinish",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      order_dat: [],
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    getOrderDetail({ order_id }).then((res) => {
      this.order_dat = res.data.order_dat;
    });
  },
  mounted() {},
  methods: {
    toHome() {
      this.$router.push({ path: "/" });
    },
    refreshOrder() {
      let order_id = this.$route.query.order_id;
      getOrderDetail({ order_id }).then((res) => {
        this.order_dat = res.data.order_dat;
      });
    },
  },
};
</script>

<style scoped>
.title-box {
  display: inline-block;
  width: 4.5em;
  margin-right: 20px;
  font-size: 13PX;
  color: #ccc;
}

.btn-refresh {
  margin-top: 0 !important;
  padding: 0.6em 1em;
}
</style>
