<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="收藏" />

    <!-- 内容 -->
    <div v-if="product_list.length > 0" class="padding-cu">
      <!-- 商品信息 -->
      <van-checkbox-group v-model="selected_product_id_list">
        <div
          v-for="(item, index) in product_list"
          :key="index"
          class="product-box"
        >
          <van-checkbox :name="item.id" shape="square"></van-checkbox>
          <img
            :src="item.thumbnail_image"
            alt="缩略图"
            class="margin-left-sm thumbnail-img"
            @click="toProductDetail(item.id)"
          />
          <div
            class="margin-left-sm flex flex-direction justify-between flex-1"
          >
            <div class="product_desc">
              <div>{{ item.brand_title }}</div>
              <div class="margin-top-xs">{{ item.title_cn }}</div>
              <div class="margin-top-sm text-sm text-bold">
                约<span class="text-gold">{{ item.selling_price_rmb }}</span
                >元
              </div>
            </div>
          </div>
        </div>
      </van-checkbox-group>

      <!-- 按钮 -->
      <div class="margin-top">
        <form>
          <input
            type="button"
            value="删除所选商品"
            class="input-button submit-after"
            @click="delProduct"
          />
        </form>
      </div>
    </div>
    <van-empty v-else :image="require('@/assets/images/logo.png')" />

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";

// 接口
import { delFavoriteProduct, getFavoriteProduct } from "../../https/http";

// vant弹窗提示
import { Dialog, Toast } from "vant";

export default {
  name: "Cart",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      product_list: [],
      selected_product_id_list: [], // 被选中的product的id
    };
  },
  created() {
    getFavoriteProduct().then((res) => {
      this.product_list = res.data;
    });
  },
  mounted() {},
  methods: {
    /**
     * 跳转到商品详情页
     */
    toProductDetail(product_id) {
      this.$router.push({
        path: "/product_detail",
        query: { product_id },
      });
    },

    /**
     * 删除商品
     */
    delProduct() {
      let product_id_list = this.selected_product_id_list
      if (product_id_list.length < 1) {
        Toast.fail("没有选择任何商品");
        return;
      }
      Dialog.confirm({
        title: "提示",
        message: "把该商品从收藏中删除吗？",
      })
        .then(() => {
          delFavoriteProduct({ product_id_list }).then((res) => {
            this.product_list = res.data;
          });
        })
        .catch((e) => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.product-box {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #5d6369;
  display: flex;
  align-items: center;
}

.product_desc {
  height: 110px;
}

.thumbnail-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.btn-margin-top {
  margin-top: 24px;
}

/deep/ .van-checkbox__label {
  color: #fff;
}
/deep/ .van-checkbox__icon--checked .van-icon {
}
/deep/ .van-checkbox__icon--checked .van-icon {
  background: #fff;
  border-color: #fff;
  color: #333;
}

/deep/ .van-empty__image {
  width: 133px;
  height: 80px;
}
</style>
