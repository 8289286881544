<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar :title="brand_mst.title_cn" />

    <!-- 内容 -->
    <div class="paragraph">
      <img v-if="brand_mst.kv_image" :src="brand_mst.kv_image" class="img" />
      <div
        v-if="brand_mst.description_cn || brand_mst.description_jp"
        class="padding-cu-sm flex justify-center text-pre-wrap margin-top-sm margin-bottom-lg"
      >
        <span class="">{{
          brand_mst.description_cn
            ? brand_mst.description_cn
            : brand_mst.description_jp
        }}</span>
      </div>

      <template v-if="brand_mst.image1">
        <video
          v-if="brand_mst.image1_ext == 'mp4'"
          :src="brand_mst.image1"
          controls
          class="img"
        ></video>
        <img v-else :src="brand_mst.image1" class="img" />
      </template>
      <div v-if="brand_mst.text1" class="padding-cu-sm flex justify-center text-pre-wrap margin-top-sm margin-bottom-lg">
        <span class="">{{ brand_mst.text1 }}</span>
      </div>

      <template v-if="brand_mst.image2">
        <video
          v-if="brand_mst.image2_ext == 'mp4'"
          :src="brand_mst.image2"
          controls
          class="img"
        ></video>
        <img v-else :src="brand_mst.image2" class="img" />
      </template>
      <div v-if="brand_mst.text2" class="padding-cu-sm flex justify-center text-pre-wrap margin-top-sm margin-bottom-lg">
        <span class="">{{ brand_mst.text2 }}</span>
      </div>

      <template v-if="brand_mst.image3">
        <video
          v-if="brand_mst.image3_ext == 'mp4'"
          :src="brand_mst.image3"
          controls
          class="img"
        ></video>
        <img v-else :src="brand_mst.image3" class="img" />
      </template>
      <div v-if="brand_mst.text3" class="padding-cu-sm flex justify-center text-pre-wrap margin-top-sm margin-bottom-lg">
        <span class="">{{ brand_mst.text3 }}</span>
      </div>

      <img v-if="brand_mst.product_list_link_image" :src="brand_mst.product_list_link_image" class="img"  @click="toProductListByBrand(brand_mst.id)"/>

    </div>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import NavigationBar from "../../components/NavigationBar.vue";
import TitleBar from "../../components/TitleBar.vue";
import Footer from "../../components/Footer.vue";

// 接口
import { getBrandDetail } from "../../https/http";

export default {
  name: "BrandTop",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
  },
  data() {
    return {
      brand_mst: {}, // 品牌信息
    };
  },
  created() {
    let brand_id = this.$route.query.brand_id;
    getBrandDetail(brand_id).then((res) => {
      this.handleBrand(res.data);
    });
  },
  mounted() {},
  methods: {
    /**
     * 处理数据
     */
    handleBrand: function (data) {
      const that = this;
      if (data.image1) {
        data.image1_ext = data.image1.split(".").pop();
      }
      if (data.image2) {
        data.image2_ext = data.image2.split(".").pop();
      }
      if (data.image3) {
        data.image3_ext = data.image3.split(".").pop();
      }
      that.brand_mst = data;
    },

    toProductListByBrand(brand_id) {
      this.$router.push({
        path: "/product_list_by_brand",
        query: { brand_id },
      });
    },
  },
};
</script>

<style scoped>
.img {
  width: 100%;
  vertical-align: top;
}

.paragraph {
  font-size: large;
  line-height: 1.5em;
}
</style>
