<template>
  <div class="footer-space">
    <!-- 头部导航 -->
    <NavigationBar />
    <!-- 标题栏 -->
    <TitleBar title="订单详情" />

    <!-- 内容 -->
    <div class="padding-cu">
      <!-- 商品信息 -->
      <div class="border-bottom-gray">
        <ProductIntroRow
          v-for="(product, index) in order_product_list"
          :key="index"
          :product="product"
        />
      </div>
      <!-- 收货信息 -->
      <div class="border-bottom-gray padding-top-lg padding-bottom-lg">
        <div>
          <span class="title-box">收货人:</span>
          <span
            >{{ order_dat.consignee_full_name
            }}<span class="margin-left-xs">{{
              order_dat.consignee_phone_number
            }}</span></span
          >
        </div>
        <div class="margin-top-sm">
          <span class="title-box">收货地址:</span>
          <span v-if="order_dat.province == order_dat.city"
            >{{ order_dat.province }}{{ order_dat.district
            }}{{ order_dat.address }}</span
          >
          <span v-else
            >{{ order_dat.province }}{{ order_dat.city }}{{ order_dat.district
            }}{{ order_dat.address }}</span
          >
        </div>
        <div class="margin-top-sm">
          <span class="title-box">配送方式:</span>
          <span>{{ order_dat.logistics_type }}</span>
        </div>
      </div>
      <!-- 商品和运费信息 -->
      <div class="border-bottom-gray padding-top-lg padding-bottom-lg">
        <div>
          <span class="title-box">商品总价:</span>
          <span
            >{{ order_dat.subtotal_text }}日元(约<span
              class="text-gold text-xs"
              >{{ order_dat.subtotal_rmb_text }}</span
            >人民币)</span
          >
        </div>
        <div class="margin-top-sm">
          <span class="title-box">运费:</span>
          <span
            >{{ order_dat.shipping_fee_text }}日元(约<span
              class="text-gold text-xs"
              >{{ order_dat.shipping_fee_rmb_text }}</span
            >人民币)</span
          >
        </div>
        <div v-if="order_dat.register_coupon_discount" class="margin-top-sm">
          <span class="title-box">优惠劵:</span>
          <span
            >-{{ order_dat.register_coupon_discount_text }}日元(约-<span
              class="text-gold text-xs"
              >{{ order_dat.register_coupon_discount_rmb_text }}</span
            >人民币)</span
          >
        </div>
        <div class="margin-top-sm">
          <span class="title-box">合计:</span>
          <span
            >{{ order_dat.total_text }}日元(约<span class="text-gold text-xs">{{
              order_dat.total_rmb_text
            }}</span
            >人民币)</span
          >
        </div>
      </div>
      <!-- 订单信息 -->
      <div class="border-bottom-gray padding-top-lg padding-bottom-lg">
        <div>
          <span class="title-box">订单号:</span>
          <span>{{ order_dat.order_no }}</span>
        </div>

        <div class="margin-top-sm">
          <span class="title-box">创建时间:</span>
          <span>{{ order_dat.registration_date }}</span>
        </div>
        <div class="margin-top-sm">
          <span class="title-box">付款时间:</span>
          <span>{{ order_dat.payment_date }}</span>
        </div>
        <div class="margin-top-sm">
          <span class="title-box">发货时间:</span>
          <span>{{ order_dat.shipping_date }}</span>
        </div>
      </div>

      <form class="flex align-center justify-end margin-top-lg">
        <input
          v-if="order_dat.status == 'PAID' || order_dat.status == 'SHIPPED'"
          type="button"
          value="确认收货"
          class="input-button submit-after"
          @click="confirmReceived(order_dat.order_id)"
        />
        <template
          v-if="
            order_dat.status == 'PAID' ||
            order_dat.status == 'SHIPPED' ||
            order_dat.status == 'RECEIVED'
          "
        >
          <input
            v-if="order_dat.is_reviewed"
            type="button"
            value="已评论"
            class="input-button submit-before margin-left-sm"
          />
          <input
            v-else
            type="button"
            value="评论"
            class="input-button submit-after margin-left-sm"
            @click="reviewtOrder()"
          />
        </template>
      </form>
    </div>

    <!-- 评论商品选择弹窗 -->
    <van-dialog v-model="show_review_product" :showConfirmButton="false">
      <div class="padding flex flex-direction align-center">
        <ProductReview
          v-for="(product, index) in review_product_list"
          :key="index"
          :product="product"
        />
        <form>
          <input
            type="button"
            value="返回"
            class="input-button submit-after margin-top-xxl"
            @click="closeReviewProduct"
          />
        </form>
      </div>
    </van-dialog>

    <!-- 尾部 -->
    <Footer />
  </div>
</template>

<script>
// 组件引入
import Footer from "../../components/Footer.vue";
import NavigationBar from "../../components/NavigationBar.vue";
import ProductIntroRow from "../../components/ProductIntroRow.vue";
import TitleBar from "../../components/TitleBar.vue";
import ProductReview from "../../components/ProductReview.vue";

// 接口
import { getOrderDetail, updateOrderStatus } from "../../https/http";


// vant弹窗提示
import { Dialog, Toast } from "vant";
import util from "../../utils/util";

export default {
  name: "OrderDetail",
  components: {
    NavigationBar,
    TitleBar,
    Footer,
    ProductIntroRow,
    ProductReview,
  },
  data() {
    return {
      order_dat: [],
      order_product_list: [],
      show_review_product: false, // 评论商品弹窗的显示
      review_product_list: [], // 评论商品列表
    };
  },
  created() {
    let order_id = this.$route.query.order_id;
    getOrderDetail({ order_id }).then((res) => {
      this.order_dat = res.data.order_dat;
      this.order_product_list = res.data.order_product_list;
    });
  },
  mounted() {},
  methods: {
    confirmReceived(order_id) {
      updateOrderStatus({ order_id, status: "RECEIVED" }).then((res) => {
        window.location.reload();
      });
    },
     /**
     * 订单评论
     */
     reviewtOrder() {
      // 如果该订单有多个商品
      if (util.isEmpty(this.order_product_list)) {
        return;
      }
      if (this.order_product_list.length > 1) {
        this.show_review_product = true;
        this.review_product_list = this.order_product_list;
      } else {
        this.toReviewInput(this.order_product_list[0].order_detail_id);
      }
    },
    /**
     * 订单评论
     */
    toReviewInput(order_detail_id) {
      this.$router.push({
        path: "/review_input",
        query: { order_detail_id },
      });
    },
    /**
     * 关闭评论商品选择弹窗
     */
    closeReviewProduct() {
      this.show_review_product = false;
    },
  },
};
</script>

<style scoped>
.title-box {
  display: inline-block;
  width: 4.5em;
  margin-right: 20px;
  font-size: 13PX;
  color: #ccc;
}

form .input-button {
  width: 7em;
  padding: 0.5em;
}
</style>
