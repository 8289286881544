<template>
  <div class="title-bar padding-cu">
    <van-icon name="arrow-left" size="20" @click="goBack"/>
    <span class="title text-lg">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  props: {
    title: String,
  },
  data() {
    return {};
  },
  methods: {
    goBack() {
      window.history.back();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title-bar {
  height: 88px;
  display: flex;
  align-items: center;
  border-bottom: 4px solid #545b60;
}

.title {
  flex: 1;
  text-align: center;
  margin-left: -20px;
}
</style>
